import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assest/logo.png";
import { HiOutlineUserCircle } from "react-icons/hi";
import { BsCartFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {  logoutRedux } from "../redux/userSlice";
import { toast } from "react-hot-toast";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleShowMenu = () => {
    setShowMenu((preve) => !preve);
  };
  const handleLogout = () => {
    dispatch(logoutRedux());
    toast("Logout successfully");
  };

  const headerStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "80px", // Adjust height as needed
    padding: "0 20px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: 50,
  };

  const smoothScroll = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const cartItemNumber = useSelector((state) => state.product.cartItem);
  return (
    <header style={headerStyles}>
      <div className="flex h-full justify-between">
        <div className="h-20 flex items-center">
          <img src={logo} alt="" className="h-full" />
        </div>

        <div className="flex items-center gap-4 md:gap-7">
          <nav className="gap-4 md:gap-6 text-base md:text-lg hidden md:flex">
            <Link
              to={""}
              onClick={() => window.scrollTo({ top: "0", behavior: "smooth" })}
            >
              Home
            </Link>
           
          </nav>
          <div className="text-2xl  relative">
            <Link onClick={smoothScroll} to={"cart"}>
              <BsCartFill />
              <div className="absolute -top-1 -right-1 text-white bg-cyan-600 h-4 w-4 rounded-full m-0 p-0 text-sm text-center ">
                {cartItemNumber.length}
              </div>
            </Link>
          </div>
          <div className="" onClick={handleShowMenu}>
            <div className="text-3xl cursor-pointer w-8 h-8 rounded-full overflow-hidden drop-shadow-md">
              {userData.image ? (
                <img src={userData.image} alt="" className="h-full w-full" />
              ) : (
                <HiOutlineUserCircle />
              )}
            </div>
            {showMenu && (
              <div className="absolute right-2 bg-white py-2  shadow drop-shadow-md flex flex-col min-w-[120px] text-center">
                {userData.email === process.env.REACT_APP_ADMIN_EMAIL && (
                  <Link
                    to={"newproduct"}
                    className="whitespace-nowrap cursor-pointer px-2"
                  >
                    New product
                  </Link>
                )}

                {userData.email ? (
                  <p
                    className="cursor-pointer text-white px-2 bg-blue-400"
                    onClick={handleLogout}
                  >
                    Logout ({userData.firstName}){" "}
                  </p>
                ) : (
                  <Link
                    to={"login"}
                    className="whitespace-nowrap cursor-pointer px-2"
                  >
                    Login  
                  
                  </Link>
                )}
                <nav className="text-base md:text-lg flex flex-col md:hidden">
                  <Link to={""} className="px-2 py-1">
                    Home
                  </Link>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
