import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
  productList: [],
  cartItem: [],
  cartTotal: 0, // Add cartTotal to the initial state
};

// Helper function to update the overall cart total
const updateCartTotal = (state) => {
  state.cartTotal = state.cartItem.reduce((total, item) => total + item.total, 0);
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setDataProduct: (state, action) => {
      state.productList = [...action.payload];
    },
    addCartItem: (state, action) => {
      const check = state.cartItem.some((el) => el._id === action.payload._id);
      if (check) {
        toast("Already Item in Cart");
      } else {
        toast("Item Add successfully");
        const total = action.payload.price;
        state.cartItem = [
          ...state.cartItem,
          { ...action.payload, qty: 1, total: total },
        ];
      }
    },
    deleteCartItem: (state, action) => {
      toast("one Item Delete");
      const index = state.cartItem.findIndex((el) => el._id === action.payload);
      state.cartItem.splice(index, 1);
      console.log(index);
      // Update the overall cart total after deleting an item
      updateCartTotal(state);
    },
    increaseQty: (state, action) => {
      const index = state.cartItem.findIndex((el) => el._id === action.payload);
      let qty = state.cartItem[index].qty;
      const qtyInc = ++qty;
      const price = state.cartItem[index].price;
      const total = price * qtyInc;

      // Create a new state object with the updated cartItem
      state.cartItem = [
        ...state.cartItem.slice(0, index),
        { ...state.cartItem[index], qty: qtyInc, total: total },
        ...state.cartItem.slice(index + 1),
      ];

      // Update the overall cart total if needed
      updateCartTotal(state);
    },

    decreaseQty: (state, action) => {
      const index = state.cartItem.findIndex((el) => el._id === action.payload);
      let qty = state.cartItem[index].qty;
      if (qty > 1) {
        const qtyDec = --qty;
        const price = state.cartItem[index].price;
        const total = price * qtyDec;

        // Create a new state object with the updated cartItem
        state.cartItem = [
          ...state.cartItem.slice(0, index),
          { ...state.cartItem[index], qty: qtyDec, total: total },
          ...state.cartItem.slice(index + 1),
        ];

        // Update the overall cart total if needed
        updateCartTotal(state);
      }
    },
  },
});

export const {
  setDataProduct,
  addCartItem,
  deleteCartItem,
  increaseQty,
  decreaseQty,
} = productSlice.actions;

export default productSlice.reducer;
