import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  firstName: "",
  image: "",
  lastName: "",
  _id: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginRedux: (state, action) => {
      console.log(action.payload.data);

      // Check if action.payload.data is defined and has the expected properties
      if (action.payload.data && action.payload.data._id) {
        state._id = action.payload.data._id;
        state.firstName = action.payload.data.firstName || "";
        state.lastName = action.payload.data.lastName || "";
        state.email = action.payload.data.email || "";
        state.image = action.payload.data.image || "";
      } else {
        console.error("Invalid payload structure in loginRedux action:", action.payload);
        // Optionally, you can set state properties to default values or handle the error in another way.
      }
    },
    logoutRedux: (state, action) => {
      state._id = "";
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.image = "";
    },
  },
});


export const { loginRedux ,logoutRedux} = userSlice.actions;

export default userSlice.reducer;