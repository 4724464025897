import React from "react";
import { GiSmokeBomb } from "react-icons/gi";



const FilterProduct = ({ category, onClick, isActive }) => {
  return (
    <div onClick={onClick}>
      <div
        className={`text-8xl p-5  rounded-full cursor-pointer ${
          isActive ? "bg-blue-400 text-white" : "bg-blue-300"
        }`}
      >
        <GiSmokeBomb  />
      </div>
      <p className="text-center text-2xl my-1 capitalize">{category}</p>
    </div>
  );
};

export default FilterProduct;
